@import '../style/variables.less';
@import '../style/mixins.less';

.ant-layout-header {
  &.guest-header {
    background: transparent;
    padding: 0 100px;
    .btn-arrow {
      padding: 0 25px;
    }
  }
  .login-link-question {
    font-weight: 600;
    white-space: nowrap;
    margin: 0 30px;
  }
}

.login {
  width: 500px;
  margin: 50px auto 0;
  border-radius: 20px;
  &.signup {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    .media-max(767px, {
      border-radius: 20px;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
    });
  }
  &-link {
    text-align: center;
  }
  .ant-card-body {
    padding: 54px 60px;
  }
  h2 {
    display: flex;
    justify-content: center;
    margin: 0 0 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }
  .ant-form-item-required {
    width: 100%;
  }
}

.signup-content-wrapper {
  margin-top: 30px;
  .signup-content {
    background-color: @primary-color;
    max-width: 1000px;
    border-radius: 20px;
    margin: 0 auto;
    background-image: url(../assets/images/sign-up-bg.jpg);
    background-position-x: -50px;
    background-position-y: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    .media-max(767px, {
      background-image: none;
      background-color: black;
    }); // how to use media mixin

    &-quote {
      .rem-font(16px);
      font-style: italic;
      font-weight: 400;
      .rem(12px); // just to show some use cases of these rem mixins
      margin-bottom: @rem;
    }
    &-signature {
      .rem-font(18px);
      font-weight: 400;
      margin-bottom: 10px;
    }
    &-logo {
      width: 110px;
      margin-bottom: 24px;
    }
    &-shields {
      display: flex;
      width: 240px;
      align-items: center;
      justify-content: space-between;
    }
    &-shield {
      width: 45px;
    }
    .login {
      width: 100.2%;
      margin: 0;
      border: none;
      height: 100%;
    }
    .signup-description {
      color: #ffffff;
      padding: 45px 54px;
      & > div {
        margin-bottom: 45px;
      }
      .check-icon {
        margin-right: 12px;
      }
      .point-heading {
        font-weight: 700;
      }
      h1 {
        .responsive-font-size(40px, 1600px, {
          .media-min(1500px, {
            font-size: 40px;
          });
          .media-max(1250px, {
            font-size: 30px;
          });
          .media-max(490px, {
            font-size: 22px;
          });
        });
        font-weight: 600;
        .rem-property(66px, margin-bottom); // just to show some use cases of these rem mixins
      }
      h1,
      h4 {
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .signup-content-wrapper {
    margin: 20px 25px;
    .signup-content {
      .signup-description {
        margin: 25px 50px 25px 25px;
      }
    }
  }
}
@media screen and (max-width: 620px) {
  .ant-layout-header {
    &.guest-header {
      padding: 0 24px;
    }
    .login-link-question {
      display: none;
    }
  }
}
@media screen and (max-width: 490px) {
  .guest-header .logo {
    width: 70px;
  }
  .login {
    width: auto;
    margin: 25px;
    .ant-card-body {
      padding: 25px;
    }
    h2 {
      font-size: 22px;
    }
  }
  .signup-content-wrapper {
    margin: 20px 25px;
    .signup-content {
      .signup-description {
        padding: 0;
        h1 {
          font-size: 22px;
        }
        & > div:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

