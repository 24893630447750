@import '../../style/variables.less';
@import '../../style/mixins.less';

.video {
  display: flex;
  flex-direction: column;
  min-height: @min-main-layout-height;
  height: 100vh;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: @btn-primary-hover-color;
    padding: 12px 36px;
    .back-link .icon {
      color: #ffffff;
      font-size: 24px;
      margin-right: 20px;
    }
    .video-name-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      overflow: hidden;
      .video-name {
        display: flex;
        align-items: center;
        flex-grow: 1;
        overflow: hidden;
        &-icon {
          color: #bdbdbd;
          font-size: 20px;
          margin-left: 10px;
        }
        &-input {
          display: block;
          & ~ .video-name-icon {
            color: #ffffff;
          }
        }
        &-text {
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          color: #bdbdbd;
          font-size: 20px;
          font-weight: 600;
          line-height: 23px;
          letter-spacing: 0em;
        }
        &-link {
          display: flex;
          align-items: center;
          color: #ffffff;
          font-weight: 700;
          height: 32px;
          padding-left: 18px;
          margin-left: 18px;
          border-left: 1px solid rgba(189, 189, 189, 0.2);
          .icon {
            font-size: 20px;
            margin-right: 10px;
          }
          span {
            white-space: nowrap;
          }
        }
        .home-link {
          color: #ffffff;
          font-size: 24px;
          margin-right: 25px;
        }
      }
      .user-email-admin {
        color: red;
        margin: 0 0 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;
      }
    }
    .header-center-wrapper {
      .ant-segmented {
        background-color: rgba(189, 189, 189, 0.15);
        .ant-segmented-item {
          color: @btn-primary-hover-color;
          padding: 0 20px;
          &-label,
          &-icon {
            display: flex;
            align-items: center;
          }
          &-selected {
            font-weight: 400;
          }
          &-label {
            padding: 0 20px;
          }
          &:not(.ant-segmented-item-selected) {
            color: #f3f3f3;
          }
          &:hover:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled) {
            color: #f3f3f3;
          }
          .icon {
            font-size: 20px;
            margin-right: 6px;
          }
        }
      }

      &.template {
        .media-max(1504px, {
          .ant-segmented-item {
            padding: 0 12px !important;
          }
        });
        .media-max(1450px, {
          .ant-segmented-item {
            padding: 0 8px !important;
          }
        });
        .media-max(1400px, {
          .ant-segmented-item {
            padding: 0px !important;
          }
        });
        .media-max(1330px, {
          .ant-segmented-item-label {
            padding: 0 14px !important;
          }
        });
      }
    }
    .header-extra {
      justify-content: flex-end;
      min-width: 30%;
      .btn-render:not(:disabled):hover {
        background-color: @primary-color;
        border-color: @primary-color;
        border-radius: @default-border-radius;
      }
      .ant-btn-primary:disabled {
        color: #ffffff;
        opacity: 0.6;
        background-color: @primary-color-light;
        border-color: @primary-color-light;
      }
      .ant-btn-default:disabled {
        color: #ffffff;
      }
    }
  }
  & > .ant-row {
    flex: 1;
  }
}

.shortcut-tooltip-title,
.shortcut-popover .shortcut-item {
  display: flex;
  align-items: center;
  .title-text {
    margin-right: 16px;
  }
  .key-wrapper {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 14px;
    background-color: rgba(49, 53, 59, 0.9);
    border-radius: @border-radius-small;
    padding: 0 4px;
    min-width: 24px;
    height: 24px;
    & ~ .key-wrapper {
      margin-left: 4px;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .icon {
      font-size: 16px;
    }
  }
}

.dropdown-menu {
  background-color: #ffffff;
  border-radius: @border-radius-xsmall;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
    rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
}
.ant-layout-header,
.ant-layout-header .ant-menu {
  height: 65px;
}

.avatar-intro-modal {
  width: 640px !important;
  .ant-modal-body {
    padding: 0;
    margin-bottom: -6px;
  }
  video {
    width: 100%;
  }
}

.sketchpicker-container {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 220px;
  margin: 10px 20px 10px 2px;

  .sketch-picker {
    padding: 10px 10px 40px !important;
  }

  .sketchpicker-controls {
    display: flex;
    justify-content: flex-end;
    gap: 4px;

    position: relative;
    top: -32px;
    right: 8px;
  }
}
.sketchpicker-container .btn-eye-dropper {
  position: absolute;
  top: 90%;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: transparent;
}
.gradient-picker {
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 1px solid @default-border-color;
  border-radius: 4px;
  overflow: hidden;
}
.gradient-color {
  width: 100%;
  height: 100%;
  &.transparent {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 2.5px;
      height: 49px;
      background-color: #cf5d60;
      transform: rotate(45deg) translate(-17px, 6px);
    }
  }
}

.sidebar .elements,
.fonts-select {
  .font-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
    margin: 0;
  }
}
.fonts-select {
  .ant-select-tree-indent,
  .ant-select-tree-switcher-noop {
    display: none;
  }
}

.sidebar .animations,
.transitions-modal {
  .effect-preview-container {
    width: 155px;
    height: 87px;
    border: 1px solid @light-border-color;
    border-radius: 6px;
    overflow: hidden;
    box-sizing: border-box;
    &.active {
      border: 2px solid @primary-color-light;
    }
    .effect-preview-thumbnail {
      display: block;
      width: 100%;
    }
  }
}

.voice-modal .voice-item,
.music .item {
  position: relative;
  &.active {
    border: 2px solid @primary-color;
    .active-icon {
      display: block;
    }
  }
  .active-icon {
    display: none;
    position: absolute;
    bottom: -2px;
    right: -2px;
    color: #ffffff;
    font-size: 10px;
    background-color: @primary-color;
    border-radius: 4px 0;
    padding: 0 4px;
  }
}

.drawer {
  overflow: hidden !important;
}

.search-media,
.uploads-media,
.templates {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-wrapper {
    flex: 1 1 auto;
    margin-top: 16px;
  }
}

.video-name-text {
  &:hover {
    cursor: pointer;
  }
}

