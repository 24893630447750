.template-scrollbar.from-pdf {
  .ant-upload-wrapper.pdf-uploader {
    .ant-upload-btn {
      padding: 18px 0!important;
    }
    .ant-upload-list-item {
      font-size: 12px;
      font-weight: 500;
    }
  }
  .select-template-label {
    display: flex;
    gap: 5px;
    margin-top: 6px;
  }
  .controls-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-select {
      margin-top: 4px;
      margin-bottom: 10px;
      width: 250px;
      .ant-select-arrow {
        margin-top: -5px;
      }
      .ant-select-clear {
        margin-top: -5px;
      }
      &-selection-placeholder {
        span {
          display: flex;
          align-items: center;
          gap: 6px;
        }
      }
    }
  }
}

