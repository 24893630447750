@import '../../../../style/variables.less';

.avatars {
  display: flex;
  flex-direction: column;
  height: 100%;
  .avatars-library-tab,
  .custom-avatars-tab {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
  }
  .avatar-item {
    &-content {
      position: relative;
    }
    &-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 9.5px;
      margin-bottom: 4px;
      &.intro {
        margin-top: 4px;
      }
    }
    &-img {
      .avatar-labels-wrapper {
        position: absolute;
        cursor: pointer;
        z-index: 1;
        bottom: 7px;
        left: 7px;
        right: 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .plus-filled-icon,
    .listener-icon,
    .edit-icon {
      position: absolute;
      top: 8px;
      z-index: 1;
    }
    .users_group-icon {
      color: #ffffff;
      border: 0;
      background: rgba(49, 53, 59, 0.6);
      font-size: 14px;
      padding-inline: 4px;
      border-radius: 7px;
      margin-inline: 0;
    }
    .edit-icon {
      left: 8px;
      font-size: 18px !important;
      color: rgb(211, 211, 211, 0.8);
    }
    .plus-filled-icon,
    .listener-icon {
      right: 8px;
    }
    .plus-filled-icon {
      color: @primary-color;
      font-size: 18px;
    }
    .edit-icon {
      cursor: pointer;
    }
    .listener-icon {
      cursor: pointer;
      font-size: 20px;
      svg path:last-child {
        fill: @primary-color;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .carousel-img {
    width: 99.5%;
    border: none;
    object-fit: cover;
  }
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }
  .custom-avatar {
    cursor: default;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: @icon-grey-color;
    &-mask {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 0.3s ease-in-out;
      .progress {
        opacity: 0;
        width: 80%;
        transition: opacity 0.3s ease-in-out;
        margin: 0;
      }
    }
    &:hover .custom-avatar-mask {
      background-color: rgba(0, 0, 0, 0.8);
      .progress {
        opacity: 1;
      }
    }
    .icon {
      font-size: 50px;
    }
  }
  .layer-control {
    margin-top: 16px;
  }
  .ant-drawer {
    outline: none;
  }
  .ant-segmented.segmented-default {
    &.avatars-libararies {
      margin-top: 24px;
    }
    &.avatar-type {
      .ant-segmented-item-label {
        font-size: 12px;
        .icon {
          margin-right: 5px;
        }
      }
    }
  }
  .avatars-title,
  .avatars-title-tags h4 {
    margin: 0 12px 0 0;
  }
  .avatars-title {
    margin-bottom: 16px;
  }
  .avatars-title-tags {
    display: flex;
    align-items: center;
    .card-tag {
      margin-inline-end: 4px;
    }
  }
  .photo-avatar-item {
    margin-right: 4px;
    &:first-child {
      margin-right: 12px;
    }
    .photo-avatar-image {
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: @default-border-radius;
      border: 1px solid @default-border-color;
    }
  }
  .card-add-avatar {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 140px;
    background: rgba(217, 217, 217, 0.1);
    padding: 8px;
    border-radius: @default-border-radius;
    border: 1px dashed @default-border-color;
    &.titled {
      padding: 12px;
      img {
        width: 48px;
        height: 48px;
        border-radius: 6px;
        margin: 1px auto 12px;
      }
      .avatar-name {
        color: @text-color;
        text-align: center;
      }
    }
    img {
      width: 72px;
      height: 72px;
      margin: auto auto 22px 22px;
    }
    .plus-icon {
      align-self: flex-end;
      font-size: 22px;
      color: @primary-color;
    }
  }
  .photo-avatar-add {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    margin-right: 12px;
    & > .icon {
      color: @icon-dark-grey-color;
      font-size: 40px;
      margin-bottom: 6px;
    }
    .card-label {
      display: flex;
      align-items: center;
      color: @primary-color;
      font-size: 12px;
      font-weight: 700;
      line-height: 100%;
      .plus-icon {
        font-size: 14px;
        margin-right: 4px;
      }
    }
  }
  .photo-avatar-image,
  .card-add-avatar.photo-avatar-add {
    width: 88px;
    height: 88px;
  }
  .horizontal-scroll {
    width: 325px;
  }
}

.drawer-avatar {
  display: flex;
  flex-direction: column;
  .avatar-img-wrapper {
    position: relative;
    display: flex;
    .listener-icon {
      cursor: pointer;
    }
    .minutes-label {
      position: absolute;
      cursor: pointer;
      z-index: 1;
      bottom: 7px;
      left: 7px;
    }
  }
}

.avatar-name {
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 110%;
}

.avatar-item,
.drawer-avatar {
  position: relative;
  &-img {
    cursor: pointer;
    position: relative;
    height: 140px;
    border: 1px solid @default-border-color;
    border-radius: @default-border-radius;
    overflow: hidden;
    transition: border 0.2s ease-in-out;
  }
  &.active {
    .avatar-item-img,
    .drawer-avatar-img {
      border: 1px solid @primary-color-light;
    }
  }
  .item-preview-btn {
    padding: 0 4px;
    font-size: 12px;
    line-height: 1rem;
    gap: 4px;
  }
}

.avatar-img-wrapper,
.avatar-item-img {
  .listener-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 20px;
    z-index: 1;
  }
  .minutes-label {
    color: #ffffff;
    border: 0;
    background: rgba(49, 53, 59, 0.6);
    border-radius: 7px;
    padding-inline: 4px;
    margin-inline-end: 6px;
  }
}

.avatar-apply-all-popconfirm {
  .ant-popconfirm-inner-content {
    display: flex;
    justify-content: space-between;
    min-width: 290px;
  }
  .ant-popconfirm-message {
    margin-bottom: 0;
    align-items: center !important;
    .current-label {
      color: #bdbdbd;
    }
    .type-label {
      color: #ffffff;
      font-weight: 700;
    }
  }
}

.avatar-apply {
  top: 77px !important;
  right: 74px !important;
  left: unset !important;
}

.avatar-filter {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.avatar-search {
  height: 32px;
  flex: 2;
  .ant-input-prefix {
    margin-inline-end: 8px;
    .icon {
      font-size: 16px;
    }
  }
}

