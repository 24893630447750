.ant-card.preview-main-card {
  width: 100%;
}
.ant-card.preview-main-card, .ant-card.preview-guest-card {
  .ant-card-body {
    iframe {
      width: 100%;
      max-height: 449px;
      max-width: 799px;
    }
  }
}

// prevent main menu closing when hover on export scorm popup
.export-scorm-popup::after {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: -1;
  pointer-events: auto;
}
