@import '../style/variables.less';

.home {
  height: 100vh;
  min-height: @min-main-layout-height;
  .mobile {
    display: none !important;
  }
  .header-alert-mobile {
    display: none;
  }
  .sider {
    display: none;
    background: #141414;
    &.open {
      display: block;
    }
    &-menu {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      background: #141414;
      .ant-menu-item {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        margin-block: 10px;
        padding: 0 15px !important;
        border-radius: @border-radius-middle;
        &:not(.ant-menu-item-selected):hover {
          background-color: @primary-color-lighter;
        }
        .icon {
          font-size: 24px;
          margin-right: 0;
        }
        .ant-menu-title-content a {
          display: flex;
          align-items: center;
        }
      }
    }
    .scrollbar {
      .scrollbar-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 16px 24px;
      }
    }
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & > div:first-child {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }
    .logo {
      max-width: 100%;
      margin-bottom: 30px;
      padding: 0;
    }
    .minutes-status-card {
      border-radius: @border-radius-middle;
      margin: 16px 0;
      .ant-typography-secondary {
        display: block;
        font-size: 14px;
        line-height: 1.2rem;
        margin-bottom: 25px;
      }
      .ant-btn-primary {
        display: block;
        margin: 0 auto 16px;
      }
      .ant-tooltip-disabled-compatible-wrapper {
        display: block !important;
        margin: 0 auto;
      }
    }
  }
  .ant-layout-header,
  .subheader-btn-container {
    .header-title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: #000;
      white-space: nowrap;
      .icon {
        font-size: 24px;
        margin-right: 6px;
      }
      a {
        display: block;
        color: #000000;
      }
    }
    .input-search-videos {
      display: block;
      min-width: 400px;
    }
    .header-admin ~ .ant-space .input-search-videos {
      min-width: 220px;
    }
    .profile-dropdown-title {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      white-space: nowrap;
    }
    .profile-dropdown-title,
    .btn-create-video {
      .up_icon {
        display: none;
      }
      &.ant-dropdown-open {
        .down_icon {
          display: none;
        }
        .up_icon {
          display: block;
        }
      }
    }
  }
  .subheader-btn-container {
    .input-search-videos {
      display: none;
    }
  }
  .ant-layout-header .header-admin {
    display: flex;
    width: 70%;
    padding: 0 15px;
    margin-left: 20px;
    .ant-menu-item,
    h4 {
      background-color: rgba(0, 0, 0, 0.03);
    }
    h4,
    .ant-menu-item a {
      display: flex;
      align-items: center;
    }
    .ant-menu-item {
      border-radius: 0;
    }
    h4 {
      font-size: 16px;
      font-weight: 600;
      padding: 0 10px;
      margin: 0;
      .icon {
        font-size: 24px;
        margin-right: 10px;
      }
    }
    .admin-logined {
      color: red;
      font-size: 16px;
      padding-left: 10px;
    }
    .icon {
      font-size: 20px;
      margin-right: 10px;
    }
  }
  .main-content-container {
    position: relative;
    background: @background-color-grey;
  }
  .christmas-banner {
    position: relative;
    padding: 14px;
    background: #cc0036;
    p {
      position: relative;
      color: #ffffff;
      font-size: 17px;
      line-height: 120%;
      text-align: center;
      margin-bottom: 0;
      z-index: 1;
      span {
        font-weight: 700;
      }
    }
    img {
      position: absolute;
      height: 100%;
      &.img-start {
        top: 0;
        left: 0;
      }
      &.img-end {
        bottom: 0;
        right: 0;
      }
    }
  }
}
.ant-dropdown ul.profile-dropdown-menu {
  min-width: 200px;
  .ant-dropdown-menu-item {
    margin: 8px 0;
    &:first-child {
      pointer-events: none;
      p {
        margin-bottom: 0;
        font-weight: 500;
      }
      &:hover {
        background-color: #ffffff;
      }
    }
    &:last-child {
      margin-top: 20px;
    }
  }
}
.ant-dropdown .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title {
  .icon {
    font-size: 20px;
    margin-right: 10px;
  }
}
.ant-dropdown .ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title {
  display: flex;
}
.profile-dropdown-menu,
.btn-create-menu {
  border-radius: 8px !important;
}
.ant-dropdown-menu.btn-create-menu .ant-dropdown-menu-item {
  margin: 6px 0;
}

@media screen and (max-width: 1200px) {
  .home .ant-layout-header .header-admin ~ .ant-space .input-search-videos {
    display: none;
  }
  .christmas-banner img {
    display: none;
  }
}

@media screen and (max-width: 1060px) {
  .home .ant-layout-header .input-search-videos {
    min-width: 250px;
  }
}

@media screen and (max-width: 880px) {
  .home .ant-layout-header .input-search-videos {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  .home {
    width: 100%;
    flex-direction: column !important;
    min-height: auto;
    height: 100vh;
    .mobile {
      display: flex !important;
    }
    .desktop {
      display: none !important;
    }
    .ant-layout-header.mobile {
      background: #141414;
      & > div:first-child {
        display: flex;
        align-items: center;
      }
      a {
        display: block;
        margin-left: 10px;
      }
      .btn-menu {
        display: flex;
        align-items: center;
        padding: 8px 15px 4px;
        color: #ffffff;
        font-size: 25px;
        svg {
          transform: rotate(90deg);
        }
      }
      .profile-dropdown-title {
        color: #ffffff;
      }
    }
    .header-alert-mobile {
      display: flex;
      align-items: flex-start;
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      line-height: 120%;
      background: linear-gradient(161deg, #6b85ff 26.31%, #7e60da 100%);
      padding: 8px 12px 13px 24px;
      border: none;
      border-radius: 0;
      .ant-alert-content,
      .icon {
        padding-top: 5px;
      }
      .icon {
        color: #ffffff;
        font-size: 24px;
        margin-inline-end: 12px;
      }
      .ant-alert-close-icon {
        margin-inline-start: 30px;
        .anticon {
          color: #ffffff;
          font-size: 16px;
        }
      }
    }
    .sider {
      width: 100% !important;
      flex: auto !important;
      max-width: 100% !important;
      .ant-layout-sider-children {
        height: 100%;
      }
    }
    .main-content {
      width: 100% !important;
    }
    .subheader-btn-container {
      display: flex !important;
      justify-content: space-between;
      padding: 20px 25px;
    }
  }
}

@media screen and (max-height: 768px) and (orientation: landscape) {
  .home {
    .sider-menu .ant-menu-item {
      margin-block: 5px;
    }
    .sider .ant-layout-sider-children {
      .logo {
        margin-bottom: 24px;
      }
      .ant-card-body {
        padding: 20px;
      }
    }
  }
}

