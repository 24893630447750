@import '../../style/variables.less';

.profile-content {
  .content {
    max-width: 460px;
    padding: 20px 30px 30px;
    border-radius: @border-radius-large;
    .btn-arrow {
      margin: 0 0 16px 0;
    }
    .btn-save-profile {
      margin: 20px 0 10px;
      padding: 0px 25px;
    }
    .primary-color-highlight {
      color: @primary-color;
    }
    .bolder {
      font-weight: 600;
    }
    .ant-page-header-heading-title {
      color: @title-dark-grey-color;
      font-size: 18px;
      font-weight: 400;
    }
    .trial-info {
      color: @text-color-secondary;
      p {
        margin: 0;
        span {
          color: @text-color;
          font-weight: 500;
        }
      }
      a {
        color: inherit;
        text-decoration: underline;
        font-weight: 500;
      }
    }
    .ant-progress {
      width: 52%;
      &-bg {
        background-color: @primary-color;
      }
    }
    .ant-collapse .ant-collapse-expand-icon .icon {
      font-size: 20px;
    }
    .render-history-collapse {
      .ant-collapse-header {
        display: flex;
        align-items: center;
        padding: 12px 0;
        .ant-collapse-expand-icon .icon {
          font-size: 24px;
        }
      }
      .ant-collapse-content-box {
        padding: 12px 0;
      }
    }
    .render-history-table {
      tr td:first-child {
        word-break: break-word;
      }
    }
  }
}
.profile-password-modal .ant-modal-confirm-btns {
  margin-top: 0;
}
.success-modal {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    img {
      margin-bottom: 30px;
    }
    h3 {
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      line-height: 2.3rem;
      margin-bottom: 24px;
    }
    .subtitle {
      width: 60%;
      text-align: center;
      margin-bottom: 30px;
    }
    .btns-container {
      display: flex;
      align-items: center;
      button + button:last-child {
        color: @text-color-secondary;
        margin-left: 20px;
        span {
          text-decoration: underline;
        }
      }
    }
  }
}

.content.delete-account,
.delete-account-modal {
  h2 {
    font-weight: 600;
    margin-bottom: 16px;
  }
}
.delete-account-modal {
  ul {
    padding-left: 20px;
    li {
      margin-bottom: 5px;
    }
  }
  .ant-modal-footer {
    margin-top: 45px;
  }
}

@media screen and (max-width: 420px) {
  .profile-content {
    .btn-save-profile {
      width: 30%;
      justify-content: center;
    }
  }
  .success-modal {
    .ant-modal-body {
      padding: 20px;
      .subtitle {
        width: 100%;
      }
      .btns-container {
        flex-direction: column;
        button:last-child {
          margin-left: 0;
        }
      }
    }
  }
}

