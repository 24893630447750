@import "../../../../../../style/variables";

#search-media-filter-video {
  gap: 0;
  .search-media-filter-apply-button {
    margin-top: 24px;
  }
}

.search-media {
  .generate-with-ai-button {
    cursor: pointer;
    height: 32px;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    margin: 8px 0 24px 0;
    border: dashed 1px white;
    border-radius: 8px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-image: linear-gradient(white, white), linear-gradient(90deg, #4868FF 0%, #C971FF 100%);
    &-text {
      user-select: none;
      font-weight: 400;
      background-image: linear-gradient(90deg, #4868FF 0%, #C971FF 100%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
    &-icon {
      color: #4868FF;
    }

  }
  .generate-with-ai-button-disabled {
    background-image: linear-gradient(white, white), linear-gradient(0deg, #979797 0%, #979797 100%);
    .generate-with-ai-button-text {
      background-image: linear-gradient(90deg, #979797 0%, #979797 100%);
    }
    .generate-with-ai-button-icon {
      color: #979797;
    }
  }
  .ant-input-search-with-button .ant-input-search-button {
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
  }
  &-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    &-filter-button {
      padding-inline: 8px!important;
    }
  }
  &-filter {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    h4 {
      color: #31353B;
    }
    .provider-select {
      min-width: 396px;
      .icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    &-entries {
      max-height: calc(100vh - 260px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,.2);
        border-radius: 3px;

        &:hover {
          background: rgba(0,0,0,.3);
        }
      }
      &-item {
        margin-top: 12px;
        &-header {
          margin-bottom: 8px;
        }
        &-content {
          button {
            display: inline-block !important;
            margin-right: 8px;
            margin-bottom: 8px;
            padding: 4px 10px;
            .ant-btn-icon {
              display: inline;
            }
            .ant-btn-icon+span, span+.ant-btn-icon, span+.anticon {
              margin-inline-start: 8px;
            }
            .azure circle {
              fill: #007FFF;
            }
            .blue circle {
              fill: #0000ff;
            }
            .chartreuse circle {
              fill: #DFFF00;
            }
            .cyan circle {
              fill: #00FFFF;
            }
            .gray circle {
              fill: #808080;
            }
            .green circle {
              fill: #008000;
            }
            .orange circle {
              fill: #FFA500;
            }
            .red circle {
              fill: #FF0000;
            }
            .rose circle {
              fill: #FF007F;
            }
            .violet circle {
              fill: #7F00FF;
            }
            .yellow circle {
              fill: #FFFF00;
            }
            .white circle {
              fill: #fff;
              stroke: #393d43;
            }
          }
        }
        &-button:has(.display-none) {
          .display-none {
            svg {
              display: none;
            }
          }
        }
      }
    }
    &-apply-button {
      margin-top: 44px;
    }
    .colors-editor {
      .color-item {
        margin: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        border: 1px solid #c6c6c6;
        padding: 1px;
        background-repeat: no-repeat;
        background-position: center;
        &.active {
          border: 1px solid #4868ff;
          box-shadow: 0 0 4px 1px #4868ff;
        }
      }
      .ant-row {
        margin-top: 8px!important;
      }
      .ant-col {
        position: unset;
        .search-media-filter-sketch-picker {
          position: unset!important;
          .sketchpicker-container {
            display: flex;
            flex-direction: column;
            top: 138px!important;
            left: -3px!important;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
            .sketch-picker {
              box-shadow: none!important;
              padding: 10px!important;
            }
            .sketchpicker-controls {
              display: flex;
              justify-content: flex-end;
              margin: 8px;
              gap: 8px;
              top: 0!important;
              right: 0!important;
            }
          }
        }
      }
      .ant-space {
        gap: 2px!important;
      }
    }
    .search-media-filter-color-picker {
      width: 25px!important;
      border: 0!important;
    }
  }
  &-tooltip {
    max-width: unset;
    .ant-tooltip-inner {
      padding: 32px;
      max-width: 470px;
      width: 460px;
      border-radius: 12px;
    }
  }
  &-wrapper {
    .element-item-img-icon {
      width: 50px;
      height: 50px;
      object-fit: fill;
    }
  }
  .generate-with-ai-drawer {
    .ant-drawer-content-wrapper {
      width: 97%;
      box-shadow: none;
      .generate-with-ai-prompt-wrapper {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .generate-with-ai-collapse {
        width: 99%;
        .ant-collapse-item {
          margin: 8px 0;
          padding: 0;
        }
        .ant-collapse-header {
          background: #D9D9D91A;
          padding: 8px 16px!important;
          border-radius: 8px;
        }
        &-content-cols {
          margin: 8px 0;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
          row-gap: 4px;
          .element-item {
            width: 98px;
            height: 98px;
            margin: 2px;
            &-selected {
              border-color: @primary-color;
              box-shadow: 0 0 4px 1px #4868ff;
            }
          }
        }
        .select-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 16px;
        }
      }
      .ant-drawer-header-title {
        display: flex;
        align-items: center;
        .ant-drawer-close {
          color: rgba(0,0,0,0.88);
          margin-inline-end: 0;
          font-weight: 500;
          font-size: 14px;
        }
        .ant-drawer-title {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
}
.select-options-with-icons {
  .ant-select-selection-item,
  .ant-select-item-option-content {
    span {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}
.filter-media-provider-select-dropdown {
  .icon {
    svg {
      width: 24px;
      height: 24px;
      path {
        stroke: @primary-color;
      }
    }
  }
}

