@import '../../../style/variables.less';

.sidebar {
  background-color: #ffffff;
  height: 100%;
  &-collapse-transparent.ant-collapse {
    margin: 0 -6px 16px 0;
    .ant-collapse-header {
      display: flex;
      align-items: center;
      padding: 0;
      .ant-collapse-expand-icon .ant-collapse-arrow {
        font-size: 24px;
      }
    }
    .ant-collapse-content > .ant-collapse-content-box {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 0 0 0;
    }
  }
  h4.sidebar-collapse-title {
    margin: 0 12px 0 0;
  }
  .back-to-arrow {
    display: flex;
    font-size: 14px;
    cursor: pointer;
    align-items: center;
    p {
      font-weight: 500;
      margin: 1px 0 0 8px;
    }
  }
  &-wrapper {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    &.open {
      width: 100%;
      .sidebar.ant-tabs {
        & > .ant-tabs-nav {
          .ant-tabs-tab {
            &-active {
              background-color: @primary-color;
              .ant-tabs-tab-btn {
                color: #ffffff;
              }
            }
          }
        }
        & > .ant-tabs-content-holder {
          width: 337px;
          border-right: 1px solid #d9d9d9;
        }
      }
      .ant-btn.btn-collapse-sidebar,
      .ant-btn.btn-collapse-sidebar:hover {
        background-color: #ffffff;
        &:after {
          box-shadow: -10px 0 0 0 #ffffff;
        }
        &:before {
          box-shadow: 10px 0 0 0 #ffffff;
        }
      }
    }
    .btn-collapse-sidebar {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 43vh;
      left: 0;
      z-index: 10;
      height: 48px;
      width: 24px;
      background-color: #e9ecec;
      border: none;
      border-radius: 5px 0 0 5px;
      box-shadow: none;
      &:before,
      &:after {
        content: '';
        position: absolute;
        right: -5px;
        height: 10px;
        width: 20px;
        transform: rotate(-90deg);
      }
      &:after {
        top: -15px;
        border-radius: 0 0 0 5px;
        box-shadow: -10px 0 0 0 #e9ecec;
      }
      &:before {
        bottom: -15px;
        border-radius: 0 0 5px 0;
        box-shadow: 10px 0 0 0 #e9ecec;
      }
      &:before,
      &:after {
        transition: box-shadow 0.2s;
      }
      &.ant-btn:hover {
        background-color: #e5e8e8;
        &:after {
          box-shadow: -10px 0 0 0 #e5e8e8;
        }
        &:before {
          box-shadow: 10px 0 0 0 #e5e8e8;
        }
        .icon {
          color: @primary-color;
        }
      }
      .icon {
        color: @btn-primary-hover-color;
        font-size: 21px;
      }
    }
  }
  &.ant-tabs {
    & > .ant-tabs-nav {
      width: 64px;
      height: calc(100% - 70px);
      .ant-tabs-tab {
        color: @btn-primary-hover-color;
        padding: 14px 0;
        &:hover {
          color: #141414;
        }
        & + .ant-tabs-tab {
          margin-top: 0;
        }
        &-active {
          .ant-tabs-tab-btn {
            color: @text-color;
          }
        }
        .ant-tabs-tab-btn {
          display: block;
          margin: 0 auto;
        }
        .main-menu-item {
          display: flex;
          flex-direction: column;
          .icon {
            font-size: 20px;
            margin: 0 auto 5px;
          }
          .comments-badge {
            color: inherit;
            margin: 0 auto;
          }
          .tab-text {
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
      .ant-tabs-nav-operations {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
      }
    }
    & > .ant-tabs-content-holder {
      width: 0px;
      transition: width 0.2s linear;
      border-right: none;
      overflow: hidden;
      & > .ant-tabs-content {
        height: 100%;
        & > .ant-tabs-tabpane {
          height: 100%;
          padding: 20px 0 0 12px;
          .tab-content {
            padding-right: 12px;
          }
        }
      }
    }
    .ant-tabs-ink-bar {
      background-color: transparent;
    }
    .ant-tabs-extra-content {
      margin-top: 12px;
      .btn-shortcuts {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        padding: 0;
        .icon {
          font-size: 20px;
        }
      }
    }
  }

  .interactivity.tab-content,
  .elements .common-options {
    padding-bottom: 55px;
  }

  .layer-control .btn-layering {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 35px;
    gap: 0;
  }
  .ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 6px 8px;
    font-size: 12px;
  }
  .ant-tabs-tab .anticon {
    margin-right: 5px;
  }
  .media-items-controls {
    padding-right: 8px;
    border-radius: 8px;
  }
  .avatars,
  .templates {
    .ant-drawer .ant-drawer-content-wrapper {
      box-shadow: none;
    }
  }

  .align-icon {
    svg {
      width: 22px;
      height: 22px;
    }
  }
  .text-controls {
    margin-bottom: 16px;
    h4 {
      margin-bottom: 0;
    }
    .ant-collapse.custom-font-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
      padding: 12px 0;
    }
  }

  .templates {
    .ant-drawer-close {
      padding: 10px;
      top: 3px;
      left: 0;
      right: inherit;
    }
    .template-drawer {
      &-scrollbar .template-container {
        cursor: pointer;
      }
      &-tags {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        border-top: 1px solid @card-border-color;
        padding-top: 10px;
        margin-top: 10px;
        .ant-tag {
          font-size: 12px;
          margin: 0 5px 5px 0;
        }
      }
    }
    .template-container {
      position: relative;
    }
    .templates-scrollbar {
      .template-drawer-tags {
        margin-bottom: 24px;
      }
    }
    .templates-empty {
      width: 100%;
    }
  }

  .music {
    display: flex;
    flex-direction: column;
    height: 100%;
    .music-tracks,
    .music-upload {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      height: 100%;
      margin-top: 16px;
    }
    .music-tracks-wrapper {
      flex: 1 1 auto;
    }
    .upload-track {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .upload-track-name {
        width: 85%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 5px;
        margin-bottom: 3px;
      }
      .icon {
        font-size: 18px;
      }
    }
    .item {
      cursor: pointer;
      position: relative;
      border-width: 2px;
      overflow: hidden;
      background: #fff;
      margin-bottom: 10px;
      border-radius: @default-border-radius;
      .ant-card-head {
        padding: 8px 12px;
        &-title {
          & > p {
            font-weight: 500;
            margin: 0;
          }
          .genre {
            margin-right: 10px;
            color: @primary-color-lighter;
          }
        }
      }
      .ant-card-extra .anticon {
        color: @icon-grey-color;
        font-size: 18px;
      }
      .ant-card-body {
        display: none;
      }
    }
  }
  .animations {
    .animation-duration-slider {
      margin-bottom: 0 !important;
      .ant-slider-dot {
        visibility: hidden;
      }
    }

    .animation-type-select {
      width: 100%;
      height: 88px;
      margin: 8px 0;
      &:hover {
        .ant-select-selector .ant-select-selection-item .effect-preview-wrapper:not(.without-preview) {
          .effect-image-wrapper {
            display: none;
          }

          .effect-preview-video {
            display: block;
          }
        }
      }

      .ant-select-selector {
        height: 100%;
        color: #31353b;

        .ant-select-selection-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;

          .effect-preview-wrapper {
            display: flex;
            align-items: center;
            overflow: hidden;

            .effect-image-wrapper {
              background-size: 160px;
              background-position: center -13px;
            }

            .effect-preview-video {
              display: none;
              width: 127%;
              margin: -10px 0 0 -13px;
            }
          }

          .effect-preview-wrapper,
          .effect-image-wrapper {
            width: 96px;
            height: 60px;
            margin-right: 0;
          }
        }
        .ant-select-selection-wrap {
          .effect-name {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .easings-select {
      .ant-select-selection-item {
        display: flex;
        align-items: center;

        .icon {
          font-size: 18px;
          margin-right: 7px;
        }
      }
    }

    .animation-time-select {
      .ant-select-selection-item {
        .icon,
        .marker-tag-wrapper {
          margin-right: 4px;
        }
      }
    }

    .easings-select,
    .animation-time-select {
      width: 100%;
      .ant-select-arrow {
        color: #000000;
        font-size: 16px;
        .icon {
          pointer-events: none;
        }
      }
    }
  }
}

.animation-type-option {
  height: 44px;

  .ant-select-item-option-content {
    height: 100%;
    display: flex;
    align-items: center;

    .effect-preview-wrapper {
      margin-right: 16px;

      .effect-image-wrapper {
        display: inline-block;
        width: 44px;
        height: 30px;
        background-size: 84px;
        background-position: center -10px;
      }

      .effect-preview-video {
        display: none;
      }
    }
  }
}

.easings-select-dropdown {
  .ant-select-item-option-content {
    display: flex;
    align-items: center;

    .icon {
      font-size: 18px;
      margin-right: 10px;
    }
  }
}

.animation-type-dropdown {
  .ant-select-item-option-content {
    .effect-image-wrapper {
      margin-right: 8px;
    }
  }
}

.effect-preview-wrapper {
  display: inline-block;
  width: 34px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 64px;
  vertical-align: middle;
}

.animation-time-dropdown {
  padding: 24px 4px;

  .ant-select-item.ant-select-item-option-active:not(.ant-select-item-option-disabled):not(.ant-select-item-option-selected) {
    background-color: #ffffff;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .input-time-option {
    display: flex;
    align-items: center;
    padding-top: 16px;
    margin: 16px 12px 0;
    border-top: 1px solid @card-border-color;

    .animation-clock {
      font-size: 24px;
      margin-right: 8px;
    }
  }
}

.animation-time-select,
.animation-time-dropdown {
  .ant-select-item-option-content,
  .ant-select-selection-item {
    & > span {
      display: flex;
      align-items: center;

      .icon {
        font-size: 24px;
        margin-right: 8px;

        &.animation-clock {
          font-size: 18px;
        }
      }

      .marker-tag {
        position: relative;
        width: 16px;
        height: 16px;
        background-color: @dark-color;
        border-radius: 3px;
        transform: rotate(45deg);

        &-digit {
          position: absolute;
          color: #ffffff;
          font-size: 12px;
          font-weight: 700;
        }

        &-wrapper {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 23px;
          height: 23px;
          margin-right: 8px;
        }
      }
    }
  }
}

