@import '../../style/variables.less';
@import '../../style/mixins.less';

.video-creation-modal {
  .mobile {
    display: none;
  }

  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header {
    padding: 16px;
    margin: 0;
  }

  .ant-modal-footer {
    background-color: @background-color;
    padding: 12px 30px;
    margin: 0;
    border-top: none;
  }

  .ant-row.option-cards-row {
    .ant-col {
      display: flex;
    }
  }

  .template-scrollbar {
    height: 375px !important;

    &.creating-video {
      height: 460px !important;
    }

    &.opened-template-name {
      height: 460px !important;
    }

    &.opened-template {
      height: 515px !important;
    }

    &.story,
    &.from-pdf,
    &.from-text,
    &.e-commerce {
      height: 430px !important;
    }

    &>div:first-child {
      overflow-x: hidden !important;
    }

    &>div:nth-child(2) {
      display: none;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 24px;

    &.without-subtitle {
      margin-bottom: 16px;
    }
  }

  .subtitle {
    width: 50%;
    color: @icon-dark-grey-color;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 0 auto 16px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
  }

  .templates-container {
    margin-bottom: 30px;
  }

  .option-container {
    .btn-arrow {
      padding: 0 25px;
    }
  }

  .text-container {
    margin: 0 auto;

    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 16px;
    }

    ul {
      padding-left: 15px;
    }

    .alert-message {
      display: flex;
      margin: 20px 0;

      span {
        margin-right: 10px;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: @warning-color;
          }
        }
      }

      p {
        color: @icon-dark-grey-color;
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 0;
      }
    }

    .ant-upload.ant-upload-drag {
      .ant-upload {
        padding: 25px 0;
      }

      p.ant-upload-text {
        color: #bdbdbd;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .card-head {
    font-size: 10px;
    height: 40px;
    padding: 8px 12px;
    margin: 0;

    .ant-card-meta-title {
      font-size: 14px;
      font-weight: 500;
      white-space: normal;
      line-height: 1.2em;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .template-media {
    width: 100%;
    border: 1px solid @light-border-color;
    border-radius: 4px;

    &.player {
      margin-bottom: 16px;
      aspect-ratio: 16/9;
    }
  }

  .template-card {
    cursor: pointer;
    flex: 1;
    min-height: 100px;
    max-height: 200px;
    max-width: 100%;
    overflow: hidden;
    border-radius: 12px;
    border: none;

    &.active {
      border: 2px solid @primary-color;
    }

    &.blank .ant-card-body {
      display: flex;
      flex-direction: column;
      width: 100%;

      img {
        width: 100%;
        margin-top: auto;
      }
    }

    &.vertical,
    &.square {
      img {
        display: block;
        margin: 0 auto;
      }
    }

    &.vertical img {
      max-width: 31.5%;
    }

    &.square img {
      max-width: 56.3%;
    }

    .card-head {
      display: flex;
      align-items: center;
      background-color: #ffffff;
    }

    .ant-card-meta-detail {
      width: 100%;
    }

    .ant-card-body {
      height: 100%;
    }

    .ant-carousel {
      min-width: 178px;
    }

    .carousel-img {
      border-radius: 0;
    }
  }

  .template-drawer-tags {
    margin-bottom: 16px;
  }

  &.templates-creation-modal {
    .title {
      margin: 0 0 30px;
    }

    .option-card {
      &>p {
        font-size: 18px;
      }

      &-hint {
        top: 45%;
      }

      .icon-circle {
        width: 80px;
        height: 80px;
      }

      .icon svg {
        width: 35px;
        height: 35px;
      }
    }

    .template-scrollbar {
      height: 430px !important;

      &.opened-template {
        height: 522px !important;
      }
    }
  }

  .story-card {
    width: 65%;
    margin: -5px auto 0 auto;
    border: none;
    border-radius: @border-radius-middle;

    .ant-card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 24px 32px;

      img {
        width: 79%;
      }

      .btn-arrow {
        margin-top: 24px;
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  .option-cards-row {
    justify-content: center;
  }

  .video-creation-modal {
    width: 78% !important;
  }
}

@media screen and (max-width: 850px) {
  .video-creation-modal {
    .text-container {
      width: 100%;
    }

    .subtitle {
      width: 70%;
    }

    .story {
      .story-card {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .video-creation-modal .format-radio-btns {
    margin-left: -4px;

    .format-radio-btn {
      margin: 0 0 10px 4px;
    }
  }
}

@media screen and (max-width: 576px) {
  .video-creation-modal {
    width: 100% !important;
    top: -20px !important;

    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    .modal-header {
      font-size: 0.85em;
    }

    .subtitle {
      width: 90%;
    }

    .template-scrollbar {

      &.e-commerce,
      &.from-text,
      &.from-pdf,
      &.story {
        height: 500px !important;
      }
    }

    .card-collapse {
      margin-bottom: 30px;

      & > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
        h4 {
          margin: 0;

          .icon {
            font-size: 18px;
            margin-right: 10px;
          }
        }
      }
    }

    .card-head .ant-card-meta-title {
      -webkit-line-clamp: 1;
    }
  }
}

@media screen and (max-width: 420px) {
  .video-creation-modal {
    .ant-modal-body {
      padding: 15px !important;
    }
  }
}

@media screen and (max-height: 750px) {
  .video-creation-modal {
    .template-scrollbar {
      &.opened-template {
        height: 450px !important;
      }
    }

    &.templates-creation-modal {
      .template-scrollbar {
        &.opened-template {
          height: 445px !important;
        }
      }
    }

    .option-cards-row {
      margin-bottom: 24px !important;

      .option-card {
        width: 225px;
        height: 110px;

        p {
          margin-top: 10px;
        }

        .icon-circle {
          margin-top: 10px;
          width: 55px;
          height: 55px;
        }

        .icon svg {
          width: 34px;
          height: 34px;
        }
      }
    }
  }
}

@media screen and (max-height: 640px) {
  .video-creation-modal {
    .template-scrollbar {
      height: 245px !important;

      &.regular {
        height: 300px !important;
      }

      &.creating-video {
        height: 100px !important;
      }

      &.opened-template-name {
        height: 245px !important;
      }

      &.opened-template {
        height: 375px !important;
      }

      &.story,
      &.from-pdf,
      &.from-text,
      &.e-commerce {
        height: 357px !important;
      }
    }

    &.templates-creation-modal {
      .template-scrollbar {
        height: 255px !important;

        &.opened-template {
          height: 368px !important;
        }
      }
    }

    .option-cards-row {
      .ant-col .option-card {
        padding: 12px 0;

        .icon-circle {
          width: 40px;
          height: 40px;
        }

        .icon svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.create-video-modal-card {
  .media-max(420px, {
    div {
      width: 100% !important;
      height: 50% !important;
    }
  });
}

