@import '../../style/variables.less';

.button-back {
  padding-inline: 0;
}
.create-workspace-modal-buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-btn-primary {
    padding: 4px 24px;
  }
}

.create-workspace-modal-users-row {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  .ant-input-wrapper {
    .ant-input-group-addon {
      width: 85px;
    }
  }
  .create-workspace-modal-remove-user-button {
    margin: 10px 8px;
  }

  .create-workspace-modal-role-select-name-row {
    padding-right: 13px;
    .anticon-question-circle {
      display: none;
    }
  }
}
