@import '../../../../../style/variables.less';

.ant-upload-wrapper {
  margin-bottom: 24px;

  &.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-bottom: 58px;

    &::before {
      display: none !important;
    }

    .ant-upload-select {
      order: -1 !important;
      width: 97px;
      height: 97px;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
    }

    .ant-upload-list-item-container {
      margin: 0;
      width: 97px;
      height: 97px;

      .ant-upload-list-item {
        cursor: pointer;
        padding: 0;
        border-radius: 4px;
        overflow: hidden;

        &::before {
          width: 100% !important;
          height: 100% !important;
        }

        &-success .ant-upload-list-item-info {
          margin: 0 auto;
        }

        .ant-upload-list-item-info {
          width: 100%;
          height: 100%;

          .ant-upload-list-item-thumbnail {
            img {
              object-fit: cover !important;
            }
            .ant-upload-list-item-thumbnail-svg {
              object-fit: contain !important;

            }
          }
        }

        .ant-upload-list-item-actions {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 5;

          .ant-upload-list-item-name {
            display: block;
            position: absolute;
            top: 22px;
            color: rgba(255, 255, 255, 0.85);
            font-size: 12px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
          }
        }
      }
    }
  }
}

.uploads-media-wrapper .uploads-scrollbar .show-more {
  position: relative;
  top: -68px;
  width: 97%;
}

.uploads-media {
  .uploads-media-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-right: 16px;

    .ant-input-search-with-button .ant-input-search-button {
      border-start-end-radius: 4px;
      border-end-end-radius: 4px;
    }

    .uploads-media-header-buttons {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      gap: 4px;
      .uploads-media-header-button {
        flex-shrink: 0;
      }
    }
  }

}

.frame-uploader {
  &.hide-uploader-btn {
    .ant-upload-select-picture-card {
      display: none;
    }

    .btn-upload-delete {
      display: none;
    }
  }

  .ant-upload-wrapper {
    display: block;
  }
}

.frame-uploader-images {
  margin-top: 16px;

  .uploads-media-header {
    padding-right: 6px;
  }

}

.disabled-upload .ant-upload.ant-upload-select {
  display: none;
}

.uploadItemImg {
  width: 100% !important;
  overflow: hidden !important;

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
    object-position: center center !important;
  }
}
