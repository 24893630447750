.interactivity-warning {
  position: relative;
  margin-bottom: 16px;
  
  &-content {
    background-color: #1E1E1E;
    color: white;
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
    
    p {
      margin-bottom: 4px;
      color: white;
    }
    
    .learn-more {
      color: #4096FF;
      text-decoration: none;
      font-weight: 500;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  &::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 20px;
    width: 12px;
    height: 12px;
    background-color: #1E1E1E;
    transform: rotate(45deg);
  }
}

.interactivity-warning-icon {
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  color: #faad14;
  font-size: 16px;
  
  svg {
    border-radius: 4px;
  }
}

.interactivity-warning-tooltip {
  p {
    margin-bottom: 4px;
    color: white;
    font-size: 14px;
    line-height: 1.4;
  }
}

.interactivity-warning-overlay {
  max-width: 300px;
  
  .ant-tooltip-arrow-content {
    background-color: #1E1E1E;
  }
  
  .ant-tooltip-inner {
    background-color: #1E1E1E;
    padding: 12px 16px;
    border-radius: 4px;
  }
} 
