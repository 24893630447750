.interactivity-modal {
  .ant-modal-content {
    padding: 0;

    .ant-modal-body {
      display: flex;

      .interactivity-modal-content {
        display: flex;
        flex-direction: column;
        padding: 48px 40px 32px;

        h3 {
          font-size: 20px;
          line-height: 120%;
          margin-bottom: 24px;
        }

        p {
          font-size: 14px;
          line-height: 140%;
        }

        .btn-ok {
          margin-top: auto;
          margin-left: auto;
        }
      }
    }
  }
}

.coming-soon-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 81px;
  background-color: rgba(240, 240, 240, 0.45);
  
  .coming-soon-panel-main {
    width: 312px;
    height: 348px;
    padding: 12px 12px 24px 12px;
    text-align: left;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    
  
    .coming-soon-panel-close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      width: 12px;
      height: 12px;
      line-height: 10px;
    }
    .coming-soon-panel-content {
      width: 288px;
      height: 284px;
      margin: 24px 8px;
      padding-right: 8px;

    .coming-soon-panel-header {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 272px;
      height: 24px;
      margin-bottom: 20px;

      .coming-soon-panel-h3 {
        padding-left: 4px;
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 0%;
        text-align: center;
      }
  }
    
    .coming-soon-panel-image {
      margin: 0 auto 20px;
      max-width: 220px;
      text-align: center;
      
      img {
        width: 100%;
        width: 216.22px;
        height: 96px;
      }
    }
    
    .coming-soon-panel-p {
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: rgba(0, 0, 0);
    }
    
    .coming-soon-panel-footer {
      display: flex;
      justify-content: flex-end;
      padding: 7.5px 8px 7.5px 24px;
      
      .ok-button {
        width: 68px;
        height: 32px;
        background-color: #FFFFFF;
        color: #000000;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
      }
    }
    }
  }
}

