@import '../../../../style/variables.less';

.interactivity {
  .controls-title {
    line-height: normal;
    margin-bottom: 16px;
  }

  .input-label {
    line-height: normal;
    margin-bottom: 8px;
  }

  .back-to-arrow {
    margin-bottom: 16px;
  }

  .slide-select {
    width: 128px;

    &-title {
      & > span {
        display: flex;
        align-items: center;

        .icon {
          font-size: 12px;
          margin-right: 8px;
        }
      }
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;

      .label {
        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-icon {
          font-size: 16px;
          margin-right: 8px;
        }
      }
    }

    .ant-select-arrow {
      color: @dark-color;
      font-size: 16px;

      .icon {
        pointer-events: none;
      }
    }
  }

  .controls-title-wrapped {
    margin-bottom: 0;
  }

  .controls-title-wrapper {
    margin-bottom: 16px;
  }

  &-general-controls {
    .sidebar-collapse-transparent.ant-collapse {
      margin: 0 0 24px 0;

      &:last-child {
        margin-bottom: 0px;
      }

      h4.sidebar-collapse-title {
        font-size: 16px;
        line-height: 1em;
      }
    }

    .control-label-bolder {
      font-weight: 500;
    }

    .elements-title {
      margin: 0;
    }

    .element-card {
      cursor: pointer;
      display: flex;
      height: 64px;
      padding: 8px 15px;
      border: 1px solid @icon-light-grey-color;
      border-radius: @default-border-radius;

      &-image {
        height: 100%;
        width: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .question-card {
      cursor: pointer;
      display: flex;
      background-color: rgba(217, 217, 217, 0.2);
      border: 1px solid @icon-light-grey-color;
      border-radius: @default-border-radius;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .tutorial-thumbnail {
      cursor: pointer;
      width: 100%;
      border-radius: @default-border-radius;
      border: 1px solid @icon-light-grey-color;
    }
  }

  &-question-controls {
    .controls-sections-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .question-textarea {
      resize: none;
      margin-bottom: 16px;
    }

    .answers-controls-title {
      margin-bottom: 12px;
    }

    .answers-controls-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 16px;

      .answer-control {
        display: flex;
        align-items: center;

        .btn-checkbox {
          color: #ffffff;

          &.checked {
            background-color: #23976b;
            border-color: #23976b;
          }

          //disable animation on click
          div {
            display: none;
          }
        }

        .btn-radio {
          display: flex;
          align-items: center;
          justify-content: center;
          color: @success-color;
          border: 2px solid @icon-light-grey-color;

          &.marked {
            border-color: @success-color;
          }

          //disable animation on click
          div {
            display: none;
          }
        }

        .btn-checkbox,
        .btn-radio {
          flex: 0 0 24px;
          margin-right: 12px;
        }

        .btn-delete {
          flex: 0 0 24px;
          color: @icon-dark-grey-color;
          margin-left: 4px;

          .icon {
            font-size: 14px;
          }
        }
      }
    }

    .input-labeled-wrapper ~ .input-labeled-wrapper {
      margin-top: 12px;
    }

    .slide-select-wrapper ~ .slide-select-wrapper {
      margin-top: 8px;
    }
  }
}

.white-coating {
  opacity: 0.3;
}

.interactivity-disabled {
  cursor: not-allowed !important;
}
.answer-tooltip-content {
  white-space: nowrap;
  margin: 0;
}

.sidebar-collapse-title {
  display: flex;
  align-items: center;
  
  .interactivity-warning-icon {
    margin-left: 8px;
  }
}

.title-info-wrapper {
  display: flex;
  align-items: center;
  
  .icon, .interactivity-warning-icon {
    margin-left: 8px;
  }
}

