@import '../../../../../style/variables.less';

.voice-modal {
  .ant-modal-content {
    overflow: hidden;
    padding: 0;
  }

  .ant-modal-body {
    padding: 20px 0;
  }

  .ant-modal-header {
    padding: 16px 24px;
    margin: 0;
  }

  .ant-modal-close {
    top: 35px;
    right: 55px;

    &:hover {
      background-color: transparent;
    }

    &-x {
      line-height: normal;
      font-size: 14px;
      font-weight: 400;
      color: #636363;
      height: 20px;

      span {
        display: flex;
        align-items: center;

        &>* {
          display: block;
        }
      }
    }
  }

  &-subheading {
    font-size: 14px;
    font-weight: 400;
  }

  .voice-scrollbar {
    height: 46vh !important;
  }

  .voice-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    min-height: 76px;
    border: 2px solid @card-border-color;

    .ant-card-body {
      display: none;
    }

    &.create-custom-item {
      background: url('../../../../../assets/images/public_custom_voice/voice-card-background.png') no-repeat center/contain;
      border: 2px dashed @card-border-color;

      .card-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 54px;
        height: 54px;
        background-color: @primary-color;
        border-radius: 100%;

        .icon {
          color: #ffffff;

          &.mic-icon {
            font-size: 20px;
            margin: 4px -4px 0 0;
          }

          &.plus-icon {
            font-size: 11px;
            margin-bottom: 16px;
          }
        }
      }

      .ant-card-meta-title {
        font-size: 18px;
        line-height: 110%;
      }

      .ant-card-meta-description {
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;
        margin-top: 6px;
      }
    }

    .ant-card-head {
      line-height: 1rem;
      padding: 0 10px;
      border-bottom: none;
      width: 100%;
    }

    .ant-card-head .ant-card-head-title {
      padding: 16px 0;

      .item-title-wrapper {
        display: flex;
        align-items: center;

        &>span {
          display: block;
        }

        .icon {
          color: @icon-dark-grey-color;
          font-size: 13px;
          margin-left: 8px;
        }
      }
    }

    .ant-card-meta {
      display: flex;
      align-items: center;
    }

    .ant-card-meta-detail>div:not(:last-child) {
      margin: 0;
    }

    .item-tags-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 4px;
      width: 100%;
    }

    .voice-item-tag {
      font-size: 10.2px;
      line-height: 1.05rem;
      padding: 0 4px;
      margin-right: 4px;
    }

    .voice-item-tag.active {
      border: 1px solid @primary-color;
    }

    .item-styles-select {
      width: 85%;
      margin-bottom: 2px;
    }

    .icon,
    .loading-icon {
      font-size: 20px;
      color: #999999;
    }

    &-premium {
      cursor: default;
      position: absolute;
      top: -2px;
      right: -2px;
      color: #ffffff;
      font-size: 10px;
      background-color: @primary-color;
      border-radius: 0 4px;
      padding: 0 6px;
    }

    .active-icon .icon {
      color: #ffffff;
      font-size: 14px;
      padding: 2px 0;
    }
  }

  .btn-apply-all {
    margin-right: 16px;

    &:not(.ant-btn:disabled) {
      background-color: @btn-primary-hover-color;
      border-color: @btn-primary-hover-color;
      border-radius: @default-border-radius;
    }
  }

  @media screen and (max-width: 770px) {
    top: 16px !important;

    .voice-modal-subheading {
      display: block;
      width: 80%;
    }
  }

  @media screen and (max-height: 700px) {
    top: 30px !important;
    width: 1150px !important;
  }
}

.margin-zero {
  margin: 0;
}

.width-100 {
  width: 100%;
}

.controls-row {
  padding: 0 24px 20px;
}

.margin-right-10px {
  margin-right: 10px;
}

.horizontal-padding {
  padding: 0 24px;
}

.display-none {
  display: none;
}

.buttom-row {
  padding: 16px 24px 0;
}

.button-col {
  display: flex;
  justify-content: flex-end;
}

.min-width160px {
  min-width: 160px;
}

.card-title {
  font-size: 14px;
  line-height: 1.2rem;
}

.card-text {
  font-size: 11px;
  margin-right: 3px;
}

.font-size-11px {
  font-size: 11px;
}


.voice-item-premium {
  height: 16px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  right: 5px !important;
  border-radius: 5px !important;
}
